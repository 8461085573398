<template>


	<TopBar />

	<div class="main-container">


	<SideBar />
 
		 


		<div class="main"> 

			<div class="shadow rounded p-3 mt-3 card-design">
			

			<div class="row">
				<div class="col-md-6 col-6">
					<h3>Nutrient Values</h3>
				</div>
				<div class="col-md-6 col-6" style="text-align: right;">
					<router-link class="btn btn-1" to="/new-nutrient-value"> <i class="bi bi-plus"></i> Add new</router-link>
				</div>
			</div>

			<div class="table-responsive">
				
				<table class="table table-hover table-striped">

					<thead>
						<tr>
							 <th>Value</th> <th>Price</th> <th>Product</th> <th>Proteins</th> <th>Carbs</th> <th>Fats</th> <th>Carlories</th> 
						</tr>
					</thead>

					<tbody>
						<tr v-for="v in values">
							<td> {{v.value}} </td>
							<td> Ksh.{{v.price}} </td>
							<td> {{v.product}} </td>
							<td> {{v.proteins}} </td>
							<td> {{v.carbs}} </td>
							<td> {{v.fats}} </td>
							<td> {{v.carlories}} </td>
							
						</tr>
					</tbody>
					
				</table>
				

			</div>


			</div>

	

		

			
		</div> 
	</div> 
	


</template>


<script>
	import SideBar from './layouts/SideBar'
	import TopBar from './layouts/TopBar'
	import Swal from 'sweetalert2'
	import axios from 'axios'

	export default{
		name : 'header',
		components : {SideBar,TopBar},
		data(){
			return{
				values : []
			}
		},
		methods : {

				async delete_category(id){

				const res = await axios.post(this.$store.state.url+'api/delete-product-category',{
					id : id 
				}).then(function(response){
					return response.data
				}).catch(function(error){
					console.log(error)
				})
				this.get_products() 
			},

			async get_values(){

				const res = await axios.get(this.$store.state.url+'api/nutrient-values').then(function(response){
					return response.data
				}).catch(function(error){
					console.log(error)
				})
				this.values = res 
			}

		},

		created(){
			this.get_values()
		}

	}

</script>