<template>


	<TopBar />

	<div class="main-container">


	<SideBar />
 
		 


		<div class="main"> 

			<div class="shadow rounded p-3 mt-3 card-design">
			

			<div class="row">
				<div class="col-md-6 col-6">
					<h3>Packages</h3>
				</div>
				<div class="col-md-6 col-6" style="text-align: right;">
					<router-link class="btn btn-1" to="/new-package"> <i class="bi bi-plus"></i> Add new</router-link>
				</div>
			</div>

			<div class="table-responsive">
				
				<table class="table table-hover table-striped">

					<thead>
						<tr>
							<th>Name</th> <th>Quantity</th> <th>Discount(%)</th>   <th>Action</th>
						</tr>
					</thead>

					<tbody>
						<tr v-for="p in packages">
							<td> {{p.name}} </td>
							<td> {{p.qty}} </td>
							<td> {{p.discount}} </td>
							
							<td> <div class="dropdown">
  <button class="btn btn-outline-success dropdown-toggle" type="button" id="dropdownMenuButton1" data-bs-toggle="dropdown" aria-expanded="false">
    Manage
  </button>
  <ul class="dropdown-menu" aria-labelledby="dropdownMenuButton1">
    <li><router-link :to=" { name : 'edit-package', params : { id : p.id } } " class="dropdown-item">Edit</router-link></li>
    <li><button  class="dropdown-item">Delete</button></li>
   
  </ul>
</div> </td>
						</tr>
					</tbody>
					
				</table>
				

			</div>


			</div>

	

		

			
		</div> 
	</div> 
	


</template>


<script>
	import SideBar from './layouts/SideBar'
	import TopBar from './layouts/TopBar'
	import Swal from 'sweetalert2'
	import axios from 'axios'

	export default{
		name : 'header',
		components : {SideBar,TopBar},
		data(){
			return{
				packages : []
			}
		},
		methods : {

			async get_packages(){

				const res = await axios.get(this.$store.state.url+'api/get-packages').then(function(response){
					return response.data
				}).catch(function(error){
					console.log(error)
				})
				this.packages = res 
			}

		},

		created(){
			this.get_packages()
		}

	}

</script>