import { createStore } from 'vuex'

export default createStore({
  state: {
    url : 'https://server.eatcleanachara.com/',
    username : localStorage.getItem('username'),
    is_package_selected : localStorage.getItem('is_package_selected'),
    selected_package : localStorage.getItem('selected_package'),
    final_total_qty : localStorage.getItem('final_total_qty'),
    cart : [],
    custom_meal_cart : [],
    package_id : localStorage.getItem('package_id'),
  },
  getters: {
  },
  mutations: {
  },
  actions: {
  },
  modules: {
  }
})
