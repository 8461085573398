<template>

<section>
	<div class="container mt-3">

		<div class="row">

			<div class="col-md-1"></div>

			<div class="col-md-5" style="background-image: url('/images/eatclean-3-2.png'); background-position: center; background-size: cover; min-height: 300px;"></div>
			<div class="col-md-5" style="padding-top: 70px; padding-bottom: 100px; background-color: #f5f5f5;">
				
				<img src="/images/logo.png" style="height: 70px;">
				<p>Please sign-in to your account</p>
				<h3>Eat Clean Achara</h3>

				<form @submit.prevent="login()">
					<div>

						<label>Username</label>
						<input type="text" v-model="username" class="form-control" placeholder="Enter a valid username">
					</div>
					<div class="mt-2">
						<label>Password</label>
						<input type="password" v-model="password" class="form-control" placeholder="Enter a valid password">
					</div>

					<div class="mt-3">
						<button class="btn btn-1 w-100" :disabled="disabled">{{text}}</button>
					</div>
				</form>

			</div>
			<div class="col-md-1"></div>
			
		</div>
		
	</div>
</section>
	
</template>


<script>
	import Swal from 'sweetalert2'
	import axios from 'axios'
	export default{
		name : 'login',
		data(){
			return{
				username : '',
				password : '',
				text : 'Login',
				disabled : false
			}
		},
		methods : {
			async login(){
				if(!this.username){
					Swal.fire({
					  position: "top-center",
					  icon: "error",
					  text: "Username is required!",
					  showConfirmButton: false,
					  timer: 1500
					})
					return
				}

				if(!this.password){
					Swal.fire({
					  position: "top-center",
					  icon: "error",
					  text: "Password is required!",
					  showConfirmButton: false,
					  timer: 1500
					})
					return
				}
				this.text = 'Please wait...'
				this.disabled = true
				const res = await axios.post(this.$store.state.url+'api/admin-login',{
					username : this.username,
					password : this.password
				}).then(function(response){
					return response.data
				}).catch(function(error){
					console.log(error)
				})

				if(res.length==0){
					Swal.fire({
					  position: "top-center",
					  icon: "error",
					  text: "Wrong username or password!",
					  showConfirmButton: false,
					  timer: 1500
					})	
				}else{
					//go to dashboard
					localStorage.setItem('username','admin')
					this.$router.push('/dashboard')
				}

				this.text = 'Login'
				this.disabled = false
			}
		}
	}
</script>