<template>


	<TopBar />

	<div class="main-container">


	<SideBar />
 
		 


		<div class="main"> 

			<div class="shadow rounded p-3 mt-3 card-design">
			

			<div class="row">
				<div class="col-md-6 col-6">
					<h3>New Product</h3>
				</div>
				<div class="col-md-6 col-6" style="text-align: right;">
					<router-link class="btn btn-1" to="/view-products"> <i class="bi bi-layers"></i> View products</router-link>
				</div>
			</div>


			<div>
				
				<form @submit.prevent="new_product()" enctype="multipart/form-data">
					<div class="row">
						<div class="col-md-6 mt-3">
							<label>Product category</label>
							<select class="form-select" v-model="category">
								<option v-for="c in categories" :value="c.id"> {{c.name}} </option>
							</select>
						</div>

						<div class="col-md-6 mt-3">
							<label>Product name</label>
							<input type="text" v-model="name" placeholder="Enter product name"  class="form-control">
						</div>

						<div class="col-md-6 mt-3">
							<label>Image</label>
							<input type="file" @change="get_image" class="form-control">
						</div>

						<div class="col-md-6 mt-3">
							<label>Type of product</label>
							<select class="form-select" v-model="product_type">
								<option value="single">Single</option>
							</select>
						</div>


						<div class="col-md-12 mt-3">
							<label>Price</label>
							<input type="text" v-model="price"  class="form-control">
						</div>


						<div class="col-md-12 mt-3">
							<label>Description</label>
							<ckeditor :editor="editor" v-model="description" :config="editorConfig"></ckeditor>
						</div>


							<div class="col-md-12 mt-3">
							<label>Ingredients</label>
						<ckeditor :editor="editor" v-model="ingredients" :config="editorConfig"></ckeditor>
						</div>


							<div class="col-md-12 mt-3">
							<label>Nutrition facts</label>
							<ckeditor :editor="editor" v-model="nutrition" :config="editorConfig"></ckeditor>
						</div>


							<div class="col-md-12 mt-3">
							<label>Heating instructions</label>
							<ckeditor :editor="editor" v-model="heating_instructions" :config="editorConfig"></ckeditor>
						</div>


						<div class="col-md-12 mt-3">
						<button class="btn btn-1" :disabled="disabled">{{text}}</button>	
						</div>

					</div>
				</form>

			</div>


			</div>

	

		

			
		</div> 
	</div> 
	


</template>


<script>
	import SideBar from './layouts/SideBar'
	import TopBar from './layouts/TopBar'
	import Swal from 'sweetalert2'
	import axios from 'axios'
	import ClassicEditor from '@ckeditor/ckeditor5-build-classic'
	import CKEditor from '@ckeditor/ckeditor5-vue'



	export default{
		name : 'header',
		components : {SideBar,TopBar, ckeditor: CKEditor.component},
		data(){
			return{
				 editor: ClassicEditor,
         description: '<p></p>',
         ingredients: '<p></p>',
         heating_instructions: '<p></p>',
         nutrition: '<p></p>',
         editorConfig: {},
				name : '',
				image : '',
				category : '',
				price : 0,
				product_type : '',
				text : 'Submit',
				disabled : false,
				categories : []
			}
		},
		methods : {

			get_image(event){
				this.image = event.target.files[0]
				
			},

				async product_categories(){

				const res = await axios.get(this.$store.state.url+'api/list-categories').then(function(response){
					return response.data
				}).catch(function(error){
					console.log(error)
				})
				this.categories = res 
			},

			async new_product(){


				if(!this.category){
					Swal.fire({
					  position: "top-center",
					  icon: "error",
					  text: "Product category is required!",
					  showConfirmButton: false,
					  timer: 1500
					})
					return
				}


				if(!this.name){
					Swal.fire({
					  position: "top-center",
					  icon: "error",
					  text: "Product name is required!",
					  showConfirmButton: false,
					  timer: 1500
					})
					return
				}


				if(!this.image){
					Swal.fire({
					  position: "top-center",
					  icon: "error",
					  text: "Image is required!",
					  showConfirmButton: false,
					  timer: 1500
					})
					return
				}

				if(!this.price){
					Swal.fire({
					  position: "top-center",
					  icon: "error",
					  text: "Price is required!",
					  showConfirmButton: false,
					  timer: 1500
					})
					return
				}

					if(!this.product_type){
					Swal.fire({
					  position: "top-center",
					  icon: "error",
					  text: "Product type is required!",
					  showConfirmButton: false,
					  timer: 1500
					})
					return
				}

				this.text = 'Please wait...'
				this.disabled = true
				 const config = {
      header : {
        'Content-Type' : 'multipart/form-data'
      }
    }

    		const form_data = new FormData()
    		form_data.append('category',this.category)
    		form_data.append('name',this.name)
    		form_data.append('image',this.image)
    		form_data.append('price',this.price)
    		form_data.append('product_type',this.product_type)
    		form_data.append('description',this.description)
    		form_data.append('ingredients',this.ingredients)
    		form_data.append('nutrition',this.nutrition)
    		form_data.append('heating_instructions',this.heating_instructions)


				const res = await axios.post(this.$store.state.url+'api/new-product',form_data,config).then(function(response){
					return response.data
				}).catch(function(error){
					console.log(error)
				})

				if(res=='success'){
					Swal.fire({
					  position: "top-center",
					  icon: "success",
					  text: "Product added successfully",
					  showConfirmButton: false,
					  timer: 1500
					})	
				}else{
					Swal.fire({
					  position: "top-center",
					  icon: "error",
					  text: "Something went wrong!",
					  showConfirmButton: false,
					  timer: 1500
					})
				}
				this.text = 'Submit'
				this.disabled = false

			}

		},

		created(){
			this.product_categories()
		}

	}

</script>