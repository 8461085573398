<template>

	<div style="position: fixed; left: 0; bottom: 0; padding-bottom: 30px; padding-left: 20px;" v-if="this.$store.state.final_total_qty">

		<div>
			<router-link class="btn btn-2 shadow-lg animate__animated animate__zoomIn" to="/cart"> <i class="bi bi-cart"></i> View Cart </router-link>
		</div>
		
	</div>


	<div style="text-align: center; background-color: #f5f5f5; padding-top: 50px; padding-bottom: 1px;">

		<img src="/images/logo2.png" class="logo">

		<h6>Eat Clean Achara</h6>
	<p>Eat Clean Achara © 2024. All Rights Reserved</p>
</div>
</template>