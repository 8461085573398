<template>


	<TopBar />

	<div class="main-container">


	<SideBar />
 
		 


		<div class="main"> 

			<div class="shadow rounded p-3 mt-3 card-design">
			

			<div class="row">
				<div class="col-md-6 col-6">
					<h3>New Nutrient Product</h3>
				</div>
				<div class="col-md-6 col-6" style="text-align: right;">
					<router-link class="btn btn-1" to="/nutrient-products"> <i class="bi bi-grid"></i> View products</router-link>
				</div>
			</div>


			<div>
				
				<form @submit.prevent="new_prod()" enctype="multipart/form-data">
					<div class="row">

						<div class="col-md-6 mt-3">
							<label>Choose category</label>
							<select class="form-select" v-model="nutrient">
								<option v-for="c in categories" :value="c.id"> {{ c.name }} </option>
							</select>
							
						</div>



						<div class="col-md-6 mt-3">
							<label>Product name</label>
							<input type="text" v-model="name" class="form-control" placeholder="Enter name">
						</div>


						<div class="col-md-12 mt-3">
						<button class="btn btn-1" :disabled="disabled">{{text}}</button>	
						</div>

					</div>
				</form>

			</div>


			</div>

	

		

			
		</div> 
	</div> 
	


</template>


<script>
	import SideBar from './layouts/SideBar'
	import TopBar from './layouts/TopBar'
	import Swal from 'sweetalert2'
	import axios from 'axios'

	export default{
		name : 'header',
		components : {SideBar,TopBar},
		data(){
			return{
				name : '',
				nutrient : '',
				text : 'Submit',
				disabled : false,
				categories : []
			}
		},
		methods : {

			async new_prod(){
				if(!this.name){
					Swal.fire({
					  position: "top-center",
					  icon: "error",
					  text: "Name is required!",
					  showConfirmButton: false,
					  timer: 1500
					})
					return
				}


				if(!this.nutrient){
					Swal.fire({
					  position: "top-center",
					  icon: "error",
					  text: "Choose nutrient category",
					  showConfirmButton: false,
					  timer: 1500
					})
					return
				}

			

				this.text = 'Please wait...'
				this.disabled = true
				

				const res = await axios.post(this.$store.state.url+'api/new-nutrient-product',{
					name : this.name,
					nutrient : this.nutrient,
				}).then(function(response){
					return response.data
				}).catch(function(error){
					console.log(error)
				})

				if(res=='success'){
					Swal.fire({
					  position: "top-center",
					  icon: "success",
					  text: "Product added successfully",
					  showConfirmButton: false,
					  timer: 1500
					})	
				}else{
					Swal.fire({
					  position: "top-center",
					  icon: "error",
					  text: res,
					  showConfirmButton: false,
					  timer: 1500
					})
				}
				this.text = 'Submit'
				this.disabled = false

				this.name = ''
				this.nutrient = ''

			},

			async get_categories(){

				const res = await axios.get(this.$store.state.url+'api/get-nutrient-categories').then(function(response){
					return response.data
				}).catch(function(error){
					console.log(error)
				})
				this.categories = res 
			}

		},

		created(){
			this.get_categories()
		}

	}

</script>