<template>


	<TopBar />

	<div class="main-container">


	<SideBar />
 
		 


		<div class="main"> 

			<div class="shadow rounded p-3 mt-3 card-design">
			

			<div class="row">
				<div class="col-md-6 col-6">
					<h3>New Nutrient Value</h3>
				</div>
				<div class="col-md-6 col-6" style="text-align: right;">
					<router-link class="btn btn-1" to="/nutrient-values"> <i class="bi bi-grid"></i> View values</router-link>
				</div>
			</div>


			<div>
				
				<form @submit.prevent="new_value()" enctype="multipart/form-data">
					<div class="row">

						<div class="col-md-4 mt-3">
							<label>Choose product</label>
							<select class="form-select" v-model="product">
								<option v-for="p in products" :value="p.id"> {{ p.name }} </option>
							</select>
							
						</div>



						<div class="col-md-4 mt-3">
							<label>Value</label>
							<input type="text" v-model="value" class="form-control" placeholder="Enter value">
						</div>


						<div class="col-md-4 mt-3">
							<label>Price</label>
							<input type="text" v-model="price" class="form-control" placeholder="Enter price">
						</div>

						<div class="col-md-4 mt-3">
							<label>Protein</label>
							<input type="text" v-model="proteins" class="form-control" placeholder="Enter value">
						</div>


						<div class="col-md-4 mt-3">
							<label>Carbs</label>
							<input type="text" v-model="carbs" class="form-control" placeholder="Enter value">
						</div>


						<div class="col-md-4 mt-3">
							<label>Fats</label>
							<input type="text" v-model="fats" class="form-control" placeholder="Enter value">
						</div>


						<div class="col-md-4 mt-3">
							<label>Carlories</label>
							<input type="text" v-model="carlories" class="form-control" placeholder="Enter value">
						</div>


						<div class="col-md-12 mt-3">
						<button class="btn btn-1" :disabled="disabled">{{text}}</button>	
						</div>

					</div>
				</form>

			</div>


			</div>

	

		

			
		</div> 
	</div> 
	


</template>


<script>
	import SideBar from './layouts/SideBar'
	import TopBar from './layouts/TopBar'
	import Swal from 'sweetalert2'
	import axios from 'axios'

	export default{
		name : 'header',
		components : {SideBar,TopBar},
		data(){
			return{
				value : '',
				product : '',
				text : 'Submit',
				disabled : false,
				products : [],
				proteins : '',
				carbs : '',
				fats : '',
				carlories : ''
			}
		},
		methods : {

			async new_value(){
				if(!this.value){
					Swal.fire({
					  position: "top-center",
					  icon: "error",
					  text: "Value is required!",
					  showConfirmButton: false,
					  timer: 1500
					})
					return
				}


				if(!this.product){
					Swal.fire({
					  position: "top-center",
					  icon: "error",
					  text: "Choose product!",
					  showConfirmButton: false,
					  timer: 1500
					})
					return
				}

				if(!this.price){
					Swal.fire({
					  position: "top-center",
					  icon: "error",
					  text: "Price is required!",
					  showConfirmButton: false,
					  timer: 1500
					})
					return
				}


				if(!this.proteins){
					Swal.fire({
					  position: "top-center",
					  icon: "error",
					  text: "Proteins is required!",
					  showConfirmButton: false,
					  timer: 1500
					})
					return
				}

				if(!this.fats){
					Swal.fire({
					  position: "top-center",
					  icon: "error",
					  text: "Fats is required!",
					  showConfirmButton: false,
					  timer: 1500
					})
					return
				}

				if(!this.carbs){
					Swal.fire({
					  position: "top-center",
					  icon: "error",
					  text: "Carbs is required!",
					  showConfirmButton: false,
					  timer: 1500
					})
					return
				}


				if(!this.carlories){
					Swal.fire({
					  position: "top-center",
					  icon: "error",
					  text: "Carlories is required!",
					  showConfirmButton: false,
					  timer: 1500
					})
					return
				}

			

				this.text = 'Please wait...'
				this.disabled = true
				

				const res = await axios.post(this.$store.state.url+'api/new-nutrient-value',{
					product : this.product,
					value : this.value,
					price : this.price,
					protein : this.proteins,
					fats : this.fats,
					carbs : this.carbs,
					carlories : this.carlories,
				}).then(function(response){
					return response.data
				}).catch(function(error){
					console.log(error)
				})

				if(res=='success'){
					Swal.fire({
					  position: "top-center",
					  icon: "success",
					  text: "Value added successfully",
					  showConfirmButton: false,
					  timer: 1500
					})	
				}else{
					Swal.fire({
					  position: "top-center",
					  icon: "error",
					  text: res,
					  showConfirmButton: false,
					  timer: 1500
					})
				}
				this.text = 'Submit'
				this.disabled = false

				this.product = ''
				this.value = ''
				this.price = ''
				this.proteins = ''
				this.carbs = ''
				this.fats = ''
				this.carlories = ''

			},

			async get_products(){

				const res = await axios.get(this.$store.state.url+'api/nutrient-products').then(function(response){
					return response.data
				}).catch(function(error){
					console.log(error)
				})
				this.products = res 
			}

		},

		created(){
			this.get_products()
		}

	}

</script>