<template>
	<Header />



	<section style="padding-top:50px; padding-bottom: 50px;">
		

		<div style="text-align:center;">
			
			<h3 class="color-3">Frequently Asked Questions</h3>
			<h1 class="color-1"> GOT A QUESTION? <br />GET YOUR ANSWER HERE! </h1>

		</div>


	</section>



	<section style="padding-bottom:100px;">
		

		<div class="container">

			<div class="accordion" id="accordionExample">
  <div class="accordion-item">
    <h2 class="accordion-header" id="headingOne">
      <button class="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
       When can I get my meals
      </button>
    </h2>
    <div id="collapseOne" class="accordion-collapse collapse show" aria-labelledby="headingOne" data-bs-parent="#accordionExample">
      <div class="accordion-body">
        <p>
          You must place and pay for your meals by 6pm if you want them delivered the following morning. Orders placed after 6pm will be delivered the following day after 12pm. 
        </p>

          <p>

We understand that sometimes you need to make a last-minute order or that you forgot to place your order in time, and we will do our best to accommodate you. However, if you order after 6pm, there is a chance that your meals may not be available for delivery until after 12 pm the following day

        </p>
      </div>
    </div>
  </div>


  <div class="accordion-item">
    <h2 class="accordion-header" id="headingTwo">
      <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
        Why choose Eat Clean Achara
      </button>
    </h2>
    <div id="collapseTwo" class="accordion-collapse collapse" aria-labelledby="headingTwo" data-bs-parent="#accordionExample">
      <div class="accordion-body">
        <p>
        	At EatClean Achara, we believe that everyone deserves to enjoy delicious and healthy food. That’s why we use only the freshest and highest-quality ingredients, and we make it low in calories and sugar. We also make our meals in a clean and sanitary environment, you can be sure that it’s safe to eat.

        </p>

        <p>
        	Order your meals today and see the difference!


        </p>
      </div>
    </div>
  </div>





</div>
			
		</div>


	</section>


	<Footer />


</template>

<script>
	import Header from './layouts/Header'
	import Footer from './layouts/Footer'
	export default{
		name : 'home',
		components : { Header,Footer }
	}

</script>