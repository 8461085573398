<template>


	<TopBar />

	<div class="main-container">


	<SideBar />
 
		 


		<div class="main"> 

			<div class="shadow rounded p-3 mt-3 card-design">

			<div>
				<h5 class="text-danger"> Order# : {{order_number}} </h5>
			</div>

			<div class="table-responsive">
				<table class="table table-hover table-striped">
					<thead>
						<tr>
							<th>Qty</th> <th> Item </th> <th> Price </th> <th>Sub total</th>
						</tr>
					</thead>

					<tbody>
						<tr v-for="i in items">
							<td> {{i.qty}} </td> <td> {{i.name}}<span class="text-success"> ({{i.category_name}}) </span> </td> <td> Ksh.{{i.price}} </td> <td> Ksh.{{i.price*i.qty}} </td>
						</tr>

						<tr v-for="i in meal_items">
							<td> {{i.qty}} </td> <td> {{i.meal}} </td> <td> Ksh.{{i.total_price}} </td> <td> Ksh.{{i.total_price*i.qty}} </td>
						</tr>

					</tbody>
					
				</table>
			</div>


			<div>
				<h5>Comments:</h5>
				{{comments}}
			</div>



			<div class="row">

				<div class="col-md-3">
					<div>
				<p>Total cost : Ksh.{{total_cost}}</p>
			</div>
				</div>


				<div class="col-md-3">
					<p>Delivery fee :  Ksh.{{delivery_fee}} </p>
				</div>


				<div class="col-md-3">
					<p>Amount to pay : Ksh.{{amount_to_pay}} </p>
				</div>

				<div class="col-md-3">
					<p>Status : {{status}} </p>
				</div>


				<div class="col-md-3">
					<p>Payment : {{payment}} </p>
				</div>


				<div class="col-md-3">
					<p>Delivery location : {{delivery_location}} </p>
				</div>
				
			</div>





			<div>
				
				<h5>Customer details</h5>

				<table class="table table-hover table-striped">

					<thead>
					<tr>
						<th> Name </th> <th>Email</th><th>Phone</th><th>Town</th><th>Address</th>
					</tr>	
					</thead>

					<tbody>
						<tr v-for="d in details">
							<td> {{d.first_name}} {{d.last_name}} </td>
							<td> {{d.email}} </td>
							<td> {{d.phone}} </td>
							<td> {{d.town}} </td>
							<td> {{d.address}} </td>
						</tr>
					</tbody>
					
				</table>
				
			</div>






			<div class="row">

				<div class="col-md-6">
					<label>Change order status</label>
					<select class="form-select" v-model="status">
						<option>received</option>
						<option>preparing</option>
						<option>delivering</option>
						<option>arrived</option>

					</select>
					<button :disabled="disabled" @click="change_status()" class="btn btn-1 mt-1"> {{text}} </button>
				</div>


				<div class="col-md-6">
					<label>Assign rider</label>
					<select class="form-select" v-model="rider_pin">
						<option :value="r.pin" v-for="r in riders">{{r.pin}} - {{r.first_name}} {{r.last_name}} </option>
					</select>
					<button :disabled="disabled1" @click="assign_rider()" class="btn btn-1 mt-1"> {{text1}} </button>
					<p v-if="rider_pin"> <i class="bi bi-check color-1"></i> A rider has been assigned to this order</p>
					<p v-if="!rider_pin">A rider has not been assigned to this order</p>
				</div>
				
			</div>


			

			

			</div>
			
		</div> 
	</div> 
	


</template>


<script>
	import SideBar from './layouts/SideBar'
	import TopBar from './layouts/TopBar'
	import Swal from 'sweetalert2'
	import axios from 'axios'

	export default{
		name : 'header',
		components : {SideBar,TopBar},
		data(){
			return{
				details : [],
				order_number : '',
				items : [],
				total_cost : 0,
				delivery_fee : 0,
				amount_to_pay : 0,
				delivery_location : '',
				status : '',
				payment : '',
				text : 'Save changes',
				disabled : false,
				text1 : 'Save changes',
				disabled1 : false,
				riders : [],
				rider_pin : '',
				meal_items : [],
				comments : ''
			}
		},
		methods : {



			async assign_rider(){
				this.text1 = 'Please wait...'
				this.disabled1 = true
				const res = await axios.post(this.$store.state.url+'api/assign-rider',{
					rider_pin : this.rider_pin,
					order_number : this.order_number
				}).then(function(response){
					return response.data
				}).catch(function(error){
					console.log(error)
				})

				Swal.fire({
					  position: "top-center",
					  icon: "info",
					  text: "Changes saved successfully",
					  showConfirmButton: false,
					  timer: 1500
					})

				this.text1 = 'Save changes'
				this.disabled1 = false
				this.order_details()
			},


			async change_status(){
				this.text = 'Please wait...'
				this.disabled = true
				const res = await axios.post(this.$store.state.url+'api/change-order-status',{
					status : this.status,
					order_number : this.order_number
				}).then(function(response){
					return response.data
				}).catch(function(error){
					console.log(error)
				})

				Swal.fire({
					  position: "top-center",
					  icon: "info",
					  text: "Changes saved successfully",
					  showConfirmButton: false,
					  timer: 1500
					})

				this.text = 'Save changes'
				this.disabled = false
				this.order_details()
			},

			async order_details(){
		
				const res = await axios.get(this.$store.state.url+'api/order-details/'+this.order_number).then(function(response){
					return response.data
				}).catch(function(error){
					console.log(error)
				})

				this.details = res 

				res.forEach((data)=>{
					this.delivery_fee = data.delivery_fee
					this.amount_to_pay = data.amount_to_pay
					this.delivery_location = data.location_name
					this.payment = data.payment
					this.status = data.status
					this.rider_pin = data.rider_pin
					this.comments  = data.comments

				})

			},


			async order_items(){
		
				const res = await axios.get(this.$store.state.url+'api/get-order-items/'+this.order_number).then(function(response){
					return response.data
				}).catch(function(error){
					console.log(error)
				})

				this.items = res 

				res.forEach((data)=>{
					this.total_cost+=parseInt(data.price*data.qty)
				})

			},


			async get_custom_meal_order_items(){
		
				const res = await axios.get(this.$store.state.url+'api/get-custom-meal-order-items/'+this.order_number).then(function(response){
					return response.data
				}).catch(function(error){
					console.log(error)
				})

				this.meal_items = res 

				res.forEach((data)=>{
					this.total_cost+=parseInt(data.total_price*data.qty)
				})

			},

			async get_riders(){
		
				const res = await axios.get(this.$store.state.url+'api/get-riders').then(function(response){
					return response.data
				}).catch(function(error){
					console.log(error)
				})

				this.riders = res 

			}

		},


		created(){
			this.order_number = this.$route.params.order_number
			this.order_details()
			this.order_items()
			this.get_custom_meal_order_items()
			this.get_riders()
		}

	}

</script>