
<template>


	<TopBar />

	<div class="main-container">


	<SideBar />
 
		 


		<div class="main"> 

			<div class="shadow rounded p-3 mt-3 card-design">
			

			<div class="row">
				<div class="col-md-6 col-6">
					<h3>Edit Location</h3>
				</div>
				<div class="col-md-6 col-6" style="text-align: right;">
					<router-link class="btn btn-1" to="/view-locations"> <i class="bi bi-grid"></i> View locations</router-link>
				</div>
			</div>


			<div>
				
				<form @submit.prevent="edit_location()" enctype="multipart/form-data">
					<div class="row">
						<div class="col-md-6 mt-3">
							<label>Location name</label>
							<input type="text" v-model="name" class="form-control" placeholder="Enter name of the location">
						</div>

						<div class="col-md-6 mt-3">
							<label>Delivery fee</label>
							<input type="text" v-model="price" placeholder="Enter delivery fee"  class="form-control">
						</div>

					

						<div class="col-md-12 mt-3">
						<button class="btn btn-1" :disabled="disabled">{{text}}</button>	
						</div>

					</div>
				</form>

			</div>


			</div>

	

		

			
		</div> 
	</div> 
	


</template>


<script>
	import SideBar from './layouts/SideBar'
	import TopBar from './layouts/TopBar'
	import Swal from 'sweetalert2'
	import axios from 'axios'

	export default{
		name : 'header',
		components : {SideBar,TopBar},
		data(){
			return{
				name : '',
				price : 0,
				text : 'Submit',
				disabled : false
			}
		},
		methods : {


			async location_details(){

				const res = await axios.get(this.$store.state.url+'api/location-details/'+this.id).then(function(response){
					return response.data
				}).catch(function(error){
					console.log(error)
				})

				res.forEach((data)=>{
					this.name = data.name 
					this.price = data.price
					
				})
				
			},

			async edit_location(){
				if(!this.name){
					Swal.fire({
					  position: "top-center",
					  icon: "error",
					  text: "Location name is required!",
					  showConfirmButton: false,
					  timer: 1500
					})
					return
				}


				if(!this.price){
					Swal.fire({
					  position: "top-center",
					  icon: "error",
					  text: "Location is required!",
					  showConfirmButton: false,
					  timer: 1500
					})
					return
				}

				

				this.text = 'Please wait...'
				this.disabled = true
				

				const res = await axios.post(this.$store.state.url+'api/edit-location',{
					name : this.name,
					price : this.price,
					id : this.id
					
				}).then(function(response){
					return response.data
				}).catch(function(error){
					console.log(error)
				})

				if(res=='success'){
					Swal.fire({
					  position: "top-center",
					  icon: "success",
					  text: "Changes saved successfully",
					  showConfirmButton: false,
					  timer: 1500
					})	
				}else{
					Swal.fire({
					  position: "top-center",
					  icon: "error",
					  text: res,
					  showConfirmButton: false,
					  timer: 1500
					})
				}
				this.text = 'Save changes'
				this.disabled = false


			}

		},

		created(){
			this.id = this.$route.params.id 
			this.location_details()
		}

	}

</script>