<template>
	<Header />



	<section style="padding-top:50px; padding-bottom: 100px;">
		

		<div class="container">

			<div class="row">

				<div class="col-md-4"></div>
				<div class="col-md-4" style="text-align:center;">
					
					<h1 class="color-1"> <i class="bi bi-truck"></i> </h1>
					<h4>Track your order</h4>

					<div class="new-message" v-if="status=='' ">
						<h3><i class="bi bi-info-circle color-1"></i></h3>
						<p>Order not found!</p>

					</div>

					<div class="new-message" v-if="status=='received' ">
						<h3><i class="bi bi-info-circle color-1"></i></h3>
						<p>Your order has been received</p>

					</div>


					<div class="new-message" v-if="status=='preparing' ">
						<h3><i class="bi bi-info-circle color-1"></i></h3>
						<p>Your order is being prepared</p>

					</div>

					<div class="new-message" v-if="status=='delivering' ">
						<h3><i class="bi bi-info-circle color-1"></i></h3>
						<p>Our rider has picked your order and is heading your way</p>

					</div>


					<div class="new-message" v-if="status=='arrived' ">
						<h3><i class="bi bi-info-circle color-1"></i></h3>
						<p>Rider has arrived</p>

					</div>


					<form @submit.prevent="order_details()">
						<input type="text" placeholder="Enter order number" v-model="order_number" class="form-control">
						<button class="btn btn-1 mt-3">Check</button>
					</form>


				</div>
				<div class="col-md-4"></div>
				
			</div>
			
		</div>
		



	</section>


	<Footer />


</template>

<script>
	import Header from './layouts/Header'
	import Footer from './layouts/Footer'
	import Swal from 'sweetalert2'
	import axios from 'axios'
	export default{
		name : 'home',
		components : { Header,Footer },
		data(){
			return{
				details : [],
				status : 'status',
				order_number : ''
			}
		},

		methods : {
			async order_details(){

				if(!this.order_number){
					Swal.fire({
					  position: "top-center",
					  icon: "info",
					  text: "Enter order number",
					  showConfirmButton: false,
					  timer: 1500
					})
					return
				}
		
				const res = await axios.get(this.$store.state.url+'api/order-details/'+this.order_number).then(function(response){
					return response.data
				}).catch(function(error){
					console.log(error)
				})

				this.details = res 

				if(res.length==0){
					this.status = ''
				}else{

					res.forEach((data)=>{
					this.status = data.status

				})

				}

				

			},
		}
	}

</script>