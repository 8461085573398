<template>
	<Header />



	<section style="padding-top:100px; padding-bottom: 100px;">
		

		<div style="text-align:center;">
			
			<h3 class="color-3">CONTACT US</h3>
			<h1><b>WE’D LOVE TO HEAR FROM YOU</b></h1>

			<p>
				If you need any assistance, don’t hesitate to get in touch with us.<br />
Send us an email or reach out to us on WhatsApp and we will get back you as soon as we can!
			</p>



			<div class="container" style="padding-top:40px;">


				<div class="row">

					<div class="col-md-4" style="text-align:center; border-right: solid 1px lightgrey;">

						<h1 class="color-1"> <i class="bi bi-whatsapp"></i> </h1>

						<h4><b>PHONE/WHATSAPP</b></h4>
						<p>Phone: (+254) 701943230<br />
						WhatsApp: (+254) 701943230</p>
						
					</div>




					<div class="col-md-4" style="text-align:center; border-right: solid 1px lightgrey;">

						<h1 class="color-1"> <i class="bi bi-geo-alt"></i> </h1>

						<h4><b>ADDRESS</b></h4>
						<p>Nairobi<br />
						Kenya</p>
						
					</div>




					<div class="col-md-4" style="text-align:center;">

						<h1 class="color-1"> <i class="bi bi-envelope"></i> </h1>

						<h4><b>EMAIL</b></h4>
						<p>info@eatcleanachara.com<br />
						order@eatcleanachara.com</p>
						
					</div>
					
				</div>
				
			</div>

		</div>


	</section>


	<Footer />


</template>

<script>
	import Header from './layouts/Header'
	import Footer from './layouts/Footer'
	export default{
		name : 'home',
		components : { Header,Footer }
	}

</script>