<template>
	<Header />



	<section style="padding-top:10px; padding-bottom: 100px;">


		<div class="container">

			<div class="row">

				<div class="col-md-4"></div>
				<div class="col-md-4">
					
					<div style="text-align:center;">

						<img src="/images/logo2.png" class="logo">
 
						<h5 class="mt-3">Rider login</h5>


						<div>
							<input v-model="pin" type="text" class="form-control" placeholder="Enter rider PIN">
						</div>


						<div class="mt-3">
							<button @click="login()" :disabled="disabled" class="btn btn-1 w-100">{{text}}</button>
						</div>

					</div>

				</div>
				<div class="col-md-4"></div>
				
			</div>
			
		</div>
		


	</section>


	<Footer />


</template>

<script>
	import Header from './layouts/Header'
	import Footer from './layouts/Footer'
	import axios from 'axios'
	import Swal from 'sweetalert2'
	export default{
		name : 'home',
		components : { Header,Footer }, 
		data(){
			return{
				pin : '',
				text : 'Login',
				disabled : false
			}
		},

		methods : {
			async login(){
				if(!this.pin){
					Swal.fire({
					  title: "Error",
					  text : 'Enter PIN!',
					  icon: "error",
					  text: "Enter PIN!",
					  showConfirmButton: true,
					  
					})
					return
				}
				this.text = 'Please wait...'
				this.disabled = true
				const res = await axios.post(this.$store.state.url+'api/rider-login',{
					pin : this.pin
				}).then(function(response){
					return response.data
				}).catch(function(error){
					console.log(error)
				})

				if(res.length==0){
					Swal.fire({
					  title: "Error",
					  text : 'Wrong PIN!',
					  icon: "error",
					  text: "Wrong PIN!",
					  showConfirmButton: true,
					})	
				}else{
					//go to dashboard

					res.forEach((data)=>{
					localStorage.setItem('pin',data.pin)	
					})

					
					this.$router.push('/rider-dashboard')
				}

				this.text = 'Login'
				this.disabled = false
			}
		}
	}

</script>