<template>


	<!-- for header part -->
	<header> 

		<div class="logosec"> 
			<img src="/images/logo2.png" class="logo"> 
			<button class="btn btn-lg menuicn color-1" id="menuicn" @click="header()"> <i class="bi bi-grid-fill"></i> </button> 
		</div> 

		<div class="searchbar"> 
			<router-link style="margin-right: 10px;" to="/view-products" class="btn btn-2"> <i class="bi bi-layers"></i> Products</router-link>
			<router-link style="margin-right: 10px;" to="/view-packages" class="btn btn-2"> <i class="bi bi-collection"></i> Packages</router-link>
			<router-link to="/view-locations" class="btn btn-2"> <i class="bi bi-truck"></i> Delivery locations</router-link>

		</div> 

		<div class="message"> 
			<div class="circle"></div>
			<div class="dp"> 
		 	<router-link style="font-size: 25px;" class="btn btn-lg" to="/orders"><i class="bi bi-chat-dots color-1"></i></router-link>
			</div> 
			<router-link to="/dashboard"><img style="border: solid 1px grey; border-radius: 50px; height: 40px;" src="/images/logo2.png"
				class="icn"
				alt="Eat Clean Achara"> </router-link>
			 
		</div> 

	</header> 

	


</template>

<script>
	
	export default{
		name : 'header',
		methods : {
			header(){
		let menuicn = document.querySelector(".menuicn"); 
let nav = document.querySelector(".navcontainer"); 
nav.classList.toggle("navclose"); 


			}
		},

		created(){
			
		}
	}

</script>