<template>
	<Header />



	<section style="padding-top:50px; padding-bottom: 100px;">

		<div class="container">

			<div class="row mb-3">
				<div class="col-md-6">
					<h6><b>Fill your pack {{this.$store.state.final_total_qty}}/{{this.$store.state.selected_package}} </b></h6>

					<div>
					<button @click="select_package(p.qty,p.id)" class="btn btn-1 package-btn" :class=" { 'btn-2' : this.$store.state.package_id == p.id } " v-for="p in packages"> {{ p.qty }} </button>

					<button @click="clear_package()" class="btn btn-danger"> Clear </button>

				</div>

				
					
				</div>


				<div class="col-md-6 mt-3" style="text-align:right;">
					<router-link to="/all-menu" class="btn btn-1"> Continue shopping </router-link>
				</div>

			</div>


			<div>
				<h3>Cart</h3>
			</div>



			<div class="table-responsive">
				<table class="table table-hover table-striped">

					<thead>
						<tr>
							<th>Product</th> <th>Price</th> <th>Qty</th> <th>Subtotal</th> <th>Action</th>
						</tr>
					</thead>

					<tbody>
						
						<tr v-for="c in this.$store.state.cart">
							<td> {{c.name}} </td>
							<td> Ksh.{{c.price}} </td>
							<td> {{c.qty}} </td>
							<td> Ksh.{{ c.price*c.qty }} </td>
							<td>
								<button @click="delete_item(c)" class="btn btn-danger"> <i class="bi bi-x"></i> </button>
							</td>
						</tr>


						<tr v-for="c in this.$store.state.custom_meal_cart">
							<td>
							 {{c.protein_category_name}} : {{c.protein_name}} - {{c.protein_value}}
							 <br />
							 {{c.carb_category_name}} : {{c.carb_name}} - {{c.carb_value}}
							 <br />
							 {{c.veg_category_name}} : {{c.veg_name}} - {{c.veg_value}}

							  </td>
							<td> Ksh.{{c.total_price}} </td>
							<td> {{c.qty}} </td>
							<td> Ksh.{{ c.total_price*c.qty }} </td>
							<td>
								<button @click="delete_from_custom_meal(c)" class="btn btn-danger"> <i class="bi bi-x"></i> </button>
							</td>
						</tr>




					</tbody>
					
				</table>
				
			</div>


			<div v-if="this.$store.state.cart.length==0 && this.$store.state.custom_meal_cart.length==0 ">
				<p class="text-danger">No items in cart</p>
			</div>



			<div class="row">

				<div class="col-md-6">
					<h5>Total cost : Ksh.{{total_cost}} <br>
					<span class="color-3">Discount : Ksh.{{discount}}</span> <br>
					Amount to pay : Ksh.{{amount_to_pay}} </h5>
					
				</div>

				<div class="col-md-6" style="text-align:right;">

					<button @click="checkout()" class="btn btn-2">Proceed to checkout</button>
					
				</div>
				
			</div>

			
		</div>
		


	</section>


	<Footer />


</template>

<script>
	import Header from './layouts/Header'
	import Footer from './layouts/Footer'
	import axios from 'axios'
	import Swal from 'sweetalert2'
	export default{
		name : 'home',
		components : { Header,Footer },
		data(){
			return{
				total_cost : 0,
				discount : 0,
				amount_to_pay : 0,
				packages : []
			}
		},

		methods : {

			checkout(){
				if(this.$store.state.selected_package>this.$store.state.final_total_qty){
					Swal.fire({
					  title: "Error",
					  icon: "error",
					  text: "Number of items in cart is less than package size",
					  showConfirmButton: true,
					  
					})
					return
				}

				if(this.$store.state.final_total_qty>this.$store.state.selected_package){

					Swal.fire({
					  title: "Error",
					  icon: "error",
					  text: "Number of items in cart exceeds package size",
					  showConfirmButton: true,
					 
					})
					return
				}

				localStorage.setItem('total_cost',this.total_cost)
				localStorage.setItem('discount',this.discount)
				localStorage.setItem('amount_to_pay',this.amount_to_pay)

				this.$router.push('/checkout')
			},

			clear_package(){

				Swal.fire({
				  title: "Are you sure",
				  text : 'Are you sure you want to clear package?',
				  icon : 'question',
				  showDenyButton: true,
				  showCancelButton: false,
				  confirmButtonText: "Yes",
				  denyButtonText: `No`
				}).then((result) => {
				  /* Read more about isConfirmed, isDenied below */
				  if (result.isConfirmed) {
				    this.clear_package1()
				  } else if (result.isDenied) {
				    //pass
				  }
				})

			},


			clear_package1(){

				localStorage.setItem('is_package_selected','no')
				localStorage.setItem('selected_package','')
				this.$store.state.is_package_selected = 'no'
				this.$store.state.selected_package = ''
				this.$store.state.final_total_qty = 0
				localStorage.setItem('final_total_qty',0)
				this.$store.state.cart = []
				this.$store.state.custom_meal_cart = []
				localStorage.setItem('cart','')
				localStorage.setItem('custom_meal_cart','')
				this.$router.push('/all-menu')
				
			},

			async get_packages(){

				const res = await axios.get(this.$store.state.url+'api/get-packages').then(function(response){
					return response.data
				}).catch(function(error){
					console.log(error)
				})
				this.packages = res 
			},

			delete_item(c){
				let qty = c.qty
				let i = this.$store.state.cart.indexOf(c)
				this.$store.state.cart.splice(i,1)
				this.$store.state.final_total_qty = this.$store.state.final_total_qty - qty
				localStorage.setItem('final_total_qty',this.$store.state.final_total_qty) 
				localStorage.setItem('cart',JSON.stringify(this.$store.state.cart))
				this.total_cost = 0 
				this.get_total_cost()
			},

			delete_from_custom_meal(c){
				let qty = c.qty
				let i = this.$store.state.custom_meal_cart.indexOf(c)
				this.$store.state.custom_meal_cart.splice(i,1)
				this.$store.state.final_total_qty = this.$store.state.final_total_qty - qty
				localStorage.setItem('final_total_qty',this.$store.state.final_total_qty) 
				localStorage.setItem('custom_meal_cart',JSON.stringify(this.$store.state.custom_meal_cart))
				this.total_cost = 0 
				this.get_total_cost()
			},

			get_total_cost(){
				this.$store.state.cart.forEach((data)=>{
					this.total_cost+=parseInt(data.price*data.qty)
				})

				this.$store.state.custom_meal_cart.forEach((data)=>{
					this.total_cost+=parseInt(data.total_price*data.qty)
				})

				this.package_details()
			},


			async package_details(){

				const res = await axios.get(this.$store.state.url+'api/package-details/'+this.$store.state.package_id).then(function(response){
					return response.data
				}).catch(function(error){
					console.log(error)
				})

				res.forEach((data)=>{
					this.discount = Math.floor( (data.discount/100)*this.total_cost)
					this.amount_to_pay = this.total_cost - this.discount
				})

				 
			},

			select_package(p,id){
				localStorage.setItem('is_package_selected','yes')
				localStorage.setItem('selected_package',p)
				localStorage.setItem('package_id',id)
				this.$store.state.is_package_selected = 'yes'
				this.$store.state.selected_package = p
				this.$store.state.package_id = id 
			},

		},

		created(){

			if(localStorage.getItem('cart')){
			this.$store.state.cart = JSON.parse(localStorage.getItem('cart'))
			this.get_total_cost()
			this.get_packages()	
			}else if(localStorage.getItem('custom_meal_cart')){
			this.$store.state.custom_meal_cart = JSON.parse(localStorage.getItem('custom_meal_cart'))
			this.get_total_cost()
			this.get_packages()	
			}else{
				this.$router.push('/all-menu')
			}

			
			
		}

	}

</script>