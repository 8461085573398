<template>
	<Header />


	<section style="padding-bottom:100px; padding-top: 100px;">
		
		<div style="text-align:center;">
			<h3 class="color-1">How It Works</h3>
			<h1>TASTE THE QUALITY IN <b>EVERY MEAL WE MAKE.</b></h1>
			<p>
				We provide fully cooked meals with the nutrients you<br />
need to change your body and the way you feel about dieting. The only commitment you have to make is to yourself
			</p>






		</div>

	</section>




	<section style="background-color: #f5f5f5; padding-top: 100px; padding-bottom: 100px;">
		

		<div style="text-align:center;">
			<h1>
				REAL, HEALTHY FOOD <b class="color-1">THAT ACTUALLY TASTES GOOD.</b>
			</h1>
			<h5>Choose from a wide variety of deliciously clean meals.</h5>



			<div class="container" style="padding-top:80px;">



				<div class="row">

			<div class="col-md-4 mt-3">

				<img src="/images/order-food-online-150x150.png">

				<h3>YOU CHOOSE</h3>
				<h5>Choose your ideal pack size and select from a wide variety of our deliciously clean meals.</h5>
				
			</div>


			<div class="col-md-4 mt-3">

				<img src="/images/we-prepare-food-150x150.png">

				<h3>WE PREPARE</h3>
				<h5>We expertly prepare, weigh, and cook your meals with high-quality ingredients.</h5>
				
			</div>



			<div class="col-md-4 mt-3">

				<img src="/images/enjoy-eat-clean-food-150x150.png">

				<h3>YOU ENJOY</h3>
				<h5>Your premium quality meals are ready to eat in minutes. Just heat and enjoy!</h5>
				
			</div>
			
		</div>


				
			</div>

		</div>


	</section>



	<section style="padding-top:100px;">
		

		<div class="container">

			<img src="/images/eatclean-3-2.png" style="with:100%;">
			
		</div>


	</section>



	<section style="padding-bottom: 100px; padding-top: 50px;">
		
		<div class="container">

			<div class="row">
				<div class="col-md-2"></div>
				<div class="col-md-8">

					<div>
						<h3 class="color-3">ORDER NOW</h3>
						<h1 class="color-1">YOUR HEALTHY LIFESTYLE STARTS HERE.</h1>
						<h5>
							Whether you're trying to lose weight, gain muscle, or simply live a healthier lifestyle, we have the meals to help you reach your goals.
						</h5>


						<router-link class="btn btn-1 mt-3" to="/all-menu">Make an order</router-link>

					</div>
					
				</div>
			</div>
			
		</div>

	</section>


	<Footer />


</template>

<script>
	import Header from './layouts/Header'
	import Footer from './layouts/Footer'
	export default{
		name : 'home',
		components : { Header,Footer }
	}

</script>