<template>


	<TopBar />

	<div class="main-container">


	<SideBar />
 
		 


		<div class="main"> 


			<div>
				<h6 class="color-3">Logged in as <b>admin</b></h6>
			</div>


			<div class="row">

				<div class="col-md-6">
					<div>
				<h3 class="color-3">Welcome Back!</h3>
			</div>
				</div>


				<div class="col-md-6" style="text-align: right;">


					
				</div>
				
			</div>


			<div class="row">

				<div class="col-md-3 mt-3">

					<div class="shadow rounded p-2 card-design-2">
					
					<div class="row">

						<div class="col-md-8 col-8">
							<h3 class="color-1"> {{categories.length}} </h3>
							<p>Product Categories</p>
						</div>

						<div class="col-md-4 col-4" style="text-align:right;">
						<h1> <i class="bi bi-grid color-1"></i> </h1>
						</div>
						
					</div>	


					<div class="border-top">
						<router-link class="btn" to="/product-categories"> <i class="bi bi-eye color-1"></i> View categories </router-link>
					</div>


					</div>
					

				</div>







				<div class="col-md-3 mt-3">

					<div class="shadow rounded p-2 card-design-2">
					
					<div class="row">

						<div class="col-md-8 col-8">
							<h3 class="color-1"> {{products.length}} </h3>
							<p>Products</p>
						</div>

						<div class="col-md-4 col-4" style="text-align:right;">
						<h1> <i class="bi bi-layers color-1"></i> </h1>
						</div>
						
					</div>	


					<div class="border-top">
						<router-link class="btn" to="/view-products"> <i class="bi bi-eye color-1"></i> View products </router-link>
					</div>


					</div>
					

				</div>



					<div class="col-md-3 mt-3">

					<div class="shadow rounded p-2 card-design-2">
					
					<div class="row">

						<div class="col-md-8 col-8">
							<h3 class="color-1"> {{packages.length}} </h3>
							<p>Packages</p>
						</div>

						<div class="col-md-4 col-4" style="text-align:right;">
						<h1> <i class="bi bi-collection color-1"></i> </h1>
						</div>
						
					</div>	


					<div class="border-top">
						<router-link class="btn" to="/view-packages"> <i class="bi bi-eye color-1"></i> View packages </router-link>
					</div>


					</div>
					

				</div>





					<div class="col-md-3 mt-3">

					<div class="shadow rounded p-2 card-design-2">
					
					<div class="row">

						<div class="col-md-8 col-8">
							<h3 class="color-1"> {{orders.length}} </h3>
							<p>Orders</p>
						</div>

						<div class="col-md-4 col-4" style="text-align:right;">
						<h1> <i class="bi bi-list color-1"></i> </h1>
						</div>
						
					</div>	


					<div class="border-top">
						<router-link class="btn" to="/orders"> <i class="bi bi-eye color-1"></i> View orders </router-link>
					</div>


					</div>
					

				</div>







				
			</div>


			

			<div class="shadow rounded p-3 mt-3 card-design ">
				<h3>Orders</h3>

					<div class="table-responsive">
				
				<table class="table table-hover table-striped">

					<thead>
						<tr>
							<th>Order#</th> <th>Total cost</th> <th>Delivery fee</th> <th>Amount to pay</th> <th>Delivery location</th> <th>Payment</th> <th>Status</th>   <th>Action</th>
						</tr>
					</thead>

					<tbody>
						<tr v-for="o in orders">
							<td> {{o.order_number}} </td>
							<td> Ksh.{{o.total_cost}} </td>
							<td> Ksh.{{o.delivery_fee}} </td>
							<td> Ksh.{{o.amount_to_pay}} </td>
							<td> {{o.location_name}} </td>
							<td> <i v-if="o.payment=='completed'" class="bi bi-check color-1"></i>
							<i v-if="o.payment!='completed'" class="bi bi-x text-danger"></i>	
							 {{o.payment}} </td>
							<td> {{o.status}} </td>
							
							<td> <div class="dropdown">
  <button class="btn btn-outline-success dropdown-toggle" type="button" id="dropdownMenuButton1" data-bs-toggle="dropdown" aria-expanded="false">
    Manage
  </button>
  <ul class="dropdown-menu" aria-labelledby="dropdownMenuButton1">
    <li><router-link :to=" { name : 'view-order', params : { order_number : o.order_number } } " class="dropdown-item">View</router-link></li>
  
   
  </ul>
</div> </td>
						</tr>
					</tbody>
					
				</table>
				

			</div>

			</div>

	

		

			
		</div> 
	</div> 
	


</template>


<script>
	import SideBar from './layouts/SideBar'
	import TopBar from './layouts/TopBar'
	import axios from "axios"
	export default{
		name : 'header',
		components : {SideBar,TopBar},
		data(){
			return{
				categories : [],
				products : [],
				packages : [],
				orders : []
			}
		},

		methods : {
				async get_products(){

				const res = await axios.get(this.$store.state.url+'api/list-products').then(function(response){
					return response.data
				}).catch(function(error){
					console.log(error)
				})
				this.products = res 
			},

			async product_categories(){

				const res = await axios.get(this.$store.state.url+'api/list-categories').then(function(response){
					return response.data
				}).catch(function(error){
					console.log(error)
				})
				this.categories = res 
			},

			async get_packages(){

				const res = await axios.get(this.$store.state.url+'api/get-packages').then(function(response){
					return response.data
				}).catch(function(error){
					console.log(error)
				})
				this.packages = res 
			},

				async get_orders(){

				const res = await axios.get(this.$store.state.url+'api/get-orders').then(function(response){
					return response.data
				}).catch(function(error){
					console.log(error)
				})
				this.orders = res 
			}
		},

		created(){
			this.product_categories()
			this.get_products()
			this.get_packages()
			this.get_orders()
		}

	}

</script>