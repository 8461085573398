<template>


	<TopBar />

	<div class="main-container">


	<SideBar />
 
		 


		<div class="main"> 

			<div class="shadow rounded p-3 mt-3 card-design">
			

			<div class="row">
				<div class="col-md-6 col-6">
					<h3>Edit Product Category</h3>
				</div>
				
			</div>


			<div>
				
				<form @submit.prevent="edit_category()" enctype="multipart/form-data">
					<div class="row">
						<div class="col-md-4 mt-3">
							<label>Category name</label>
							<input type="text" v-model="name" class="form-control" placeholder="Enter category name">
						</div>

						<div class="col-md-4 mt-3">
							<label>Image</label>
							<input type="file" @change="get_image"  class="form-control">
						</div>

						<div class="col-md-4 mt-3">
							<label>Priority</label>
							<input type="number" min="0" v-model="priority" class="form-control" placeholder="Enter priority">
						</div>


						<div class="col-md-12 mt-3">
							<label>Description</label>
							<textarea v-model="description" placeholder="Enter category description" class="form-control" rows="6"></textarea>
						</div>


						<div class="col-md-12 mt-3">
						<button class="btn btn-1" :disabled="disabled">{{text}}</button>	
						</div>

					</div>
				</form>

			</div>


			</div>

	

		

			
		</div> 
	</div> 
	


</template>


<script>
	import SideBar from './layouts/SideBar'
	import TopBar from './layouts/TopBar'
	import Swal from 'sweetalert2'
	import axios from 'axios'

	export default{
		name : 'header',
		components : {SideBar,TopBar},
		data(){
			return{
				name : '',
				image : '',
				priority : '',
				description : '',
				text : 'Save changes',
				disabled : false,
				id : ''
			}
		},
		methods : {

			async get_details(){

				const res = await axios.get(this.$store.state.url+'api/product-category-details/'+this.id ).then(function(response){
					return response.data
				}).catch(function(error){
					console.log(error)
				})
				res.forEach((data)=>{
					this.name = data.name 
					this.priority = data.priority
					this.description = data.description
				}) 
			},


			get_image(event){
				this.image = event.target.files[0]
				
			},

			async edit_category(){
				if(!this.name){
					Swal.fire({
					  position: "top-center",
					  icon: "error",
					  text: "Category name is required!",
					  showConfirmButton: false,
					  timer: 1500
					})
					return
				}



				if(!this.priority){
					Swal.fire({
					  position: "top-center",
					  icon: "error",
					  text: "Priority is required!",
					  showConfirmButton: false,
					  timer: 1500
					})
					return
				}

				this.text = 'Please wait...'
				this.disabled = true
				 const config = {
      header : {
        'Content-Type' : 'multipart/form-data'
      }
    }

    const formDataDoc = new FormData()
    formDataDoc.append('image', this.image)
    formDataDoc.append('name', this.name)
    formDataDoc.append('priority', this.priority)
    formDataDoc.append('description', this.description)
    formDataDoc.append('id', this.id)

				const res = await axios.post(this.$store.state.url+'api/edit-product-category',formDataDoc,config).then(function(response){
					return response.data
				}).catch(function(error){
					console.log(error)
				})

				if(res=='success'){
					Swal.fire({
					  position: "top-center",
					  icon: "success",
					  text: "Changes saved successfully",
					  showConfirmButton: false,
					  timer: 1500
					})	
				}else{
					Swal.fire({
					  position: "top-center",
					  icon: "error",
					  text: 'Something went wrong!',
					  showConfirmButton: false,
					  timer: 1500
					})
				}
				this.text = 'Save changes'
				this.disabled = false

			}

		},

		created(){
			this.id = this.$route.params.id
			this.get_details() 
		}

	}

</script>