<template>
	<Header />



	<section style="padding-top:0px; padding-bottom:100px;">



		<div class="container">


		<div style="text-align:center;">

			<h3>
			<b>Create your own meal</b>
			</h3>
			
			


			<div class="mb-3" v-if="this.$store.state.is_package_selected!='yes' ">
					
					<p><span class="color-3">You have not selected a package yet</span><br>
				<b>Select a package below</b></p>

				<div>
					<button @click="select_package(p.qty,p.id)" class="btn btn-1 package-btn" v-for="p in packages"> {{ p.qty }} </button>
				</div>

				</div>
		

		</div>




		<div class="row">
			<div class="col-md-6">





				<!-- proteins -->
				<div class="mt-1">
				
				<h6><b> Proteins </b> </h6>

				

				<div class="row">

					<div class="col-md-8 col-6">
					
					<select class="form-select" v-model="protein_product_id
					" @change="get_protein_product_values()">
					<option selected value="">Select protein</option>
						<option v-for="p in  protein_products" :value="p.id">{{p.name}}</option>
					</select>

					</div>


					<div class="col-md-4 col-6">

						<select class="form-select" v-model="protein_value_id" @change="get_protein_value_details()">
					<option selected value="">Select quantity</option>
						<option v-for="v in  protein_product_values" :value="v.id">{{v.value}} - Ksh.{{v.price}} </option>
					</select>
					
					</div>
					
				</div>
				<div>

					<p v-if="protein_value_details.length==0 || protein_value_id=='' ">Protein : <b> 0 </b> Carbs : <b>0</b> Fat : <b>0</b>  Carlories : <b>0</b> </p>

					<p v-if="protein_product_id!='' && protein_value_id!='' " v-for="d in protein_value_details">Protein : <b> {{d.proteins}} </b> Carbs : <b>{{d.carbs}}</b> Fat : <b>{{d.fats}}</b>  Carlories : <b>{{d.carlories}}</b> </p>
				</div>

				</div>









				<!-- carbs -->
				<div class="mt-1">
				
				<h6><b> Carbohydrates </b> </h6>

				

				<div class="row">

					<div class="col-md-8 col-6">
					
					<select class="form-select" v-model="carb_product_id
					" @change="get_carb_product_values()">
					<option selected value="">Select carbohydrate</option>
						<option v-for="c in  carb_products" :value="c.id">{{c.name}}</option>
					</select>

					</div>


					<div class="col-md-4 col-6">

						<select class="form-select" v-model="carb_value_id" @change="get_carb_value_details()">
					<option selected value="">Select quantity</option>
						<option v-for="v in  carb_product_values" :value="v.id">{{v.value}} - Ksh.{{v.price}} </option>
					</select>
					
					</div>
					
				</div>
				<div>

					<p v-if="carb_value_details.length==0 || carb_value_id=='' ">Protein : <b> 0 </b> Carbs : <b>0</b> Fat : <b>0</b>  Carlories : <b>0</b> </p>

					<p v-if="carb_product_id!='' && carb_value_id!='' && carb_value_details.length>0 " v-for="d in carb_value_details">Protein : <b> {{d.proteins}} </b> Carbs : <b>{{d.carbs}}</b> Fat : <b>{{d.fats}}</b>  Carlories : <b>{{d.carlories}}</b> </p>
				</div>

				</div>








		<!-- veg -->
				<div class="mt-1">
				
				<h6><b> Vegetables </b> </h6>

				

				<div class="row">

					<div class="col-md-8 col-6">
					
					<select class="form-select" v-model="veg_product_id
					" @change="get_veg_product_values()">
					<option selected value="">Select vegetable</option>
						<option v-for="v in  veg_products" :value="v.id">{{v.name}}</option>
					</select>

					</div>


					<div class="col-md-4 col-6">

						<select class="form-select" v-model="veg_value_id" @change="get_veg_value_details()">
					<option selected value="">Select quantity</option>
						<option v-for="v in  veg_product_values" :value="v.id">{{v.value}} - Ksh.{{v.price}} </option>
					</select>
					
					</div>
					
				</div>
				<div>

					<p v-if="veg_value_details.length==0 || veg_value_id=='' ">Protein : <b> 0 </b> Carbs : <b>0</b> Fat : <b>0</b>  Carlories : <b>0</b> </p>

					<p v-if="veg_product_id!='' && veg_value_id!='' && veg_value_details.length>0 " v-for="d in veg_value_details">Protein : <b> {{d.proteins}} </b> Carbs : <b>{{d.carbs}}</b> Fat : <b>{{d.fats}}</b>  Carlories : <b>{{d.carlories}}</b> </p>
				</div>

				</div>











			</div>







			<div class="col-md-6" style="padding-top:20px;">

				<div>
					
					<div>
						<h5>Total Quantity:</h5>
						<h5>Protein : <b> {{total_proteins}} </b> Carbs : <b>{{total_carbs}}</b> Fat : <b>{{total_fats}}</b>  Carlories : <b>{{total_carlories}}</b> </h5>
					</div>

					<img src="/images/collage-assortment-dishes-from-different-countries-world-food-snacks_187166-69374.jpeg" style="width: 100%;" class="rounded">

					<div style="padding-top:10px;">
						<div class="row">
							<div class="col-md-4">
								<h5>Custom Meal</h5>
						<h4><b>Ksh.{{total_price}}</b></h4>
							</div>

							<div class="col-md-4 col-6">


				<div class="mb-3">

					<button @click="decrement()" class="btn btn-1"> <i class="bi bi-dash"></i> </button>
					{{qty}}
					<button class="btn btn-1" @click="increment()"> <i class="bi bi-plus"></i> </button>

					
				</div>
								
							</div>

							<div class="col-md-4 col-6" style="text-align:right;">
								<button class="btn btn-1" @click="add_to_cart()">Add to cart</button>
							</div>
							
						</div>
					</div>

				</div>
				
			</div>





			




		
		</div>


			

		</div>
		

		


	</section>


	<section>
		<div class="container">

		
			
		</div>

	</section>


	<Footer />


</template>

<script>
	import Header from './layouts/Header'
	import Footer from './layouts/Footer'
	import axios from 'axios'
	import Swal from 'sweetalert2'
	export default{
		name : 'home',
		components : { Header,Footer },
		data(){
			return{
				items : [],
				id : '',
				packages : [],
				qty : 1,
				protein_products : [],
				protein_product_id : '',
				protein_product_values : [],
				protein_value_id : '',
				protein_value_details : [],
				total_proteins : 0,
				total_carbs : 0,
				total_fats : 0,
				total_carlories : 0,
				total_price : 0,
				carb_products : [],
				carb_product_id : '',
				carb_product_values : [],
				carb_value_id : '',
				carb_value_details : [],
				veg_products : [],
				veg_product_id : '',
				veg_product_values : [],
				veg_value_id : '',
				veg_value_details : [],
				protein_category_name : '',
				protein_name : '',
				protein_value : '',
				carb_category_name : '',
				carb_name : '',
				carb_value : '',
				veg_category_name : '',
				veg_name : '',
				veg_value : '',


			}
		},

		methods : {

			decrement(){
				if(this.qty<2){
					//pass
				}else{
					this.qty--
				}
			},

			increment(){
				
				if(this.qty < this.$store.state.selected_package ){
					this.qty++
				}else{

					Swal.fire({
					  title: "Error",
					  icon: "error",
					  text: "Quantity of items exceeds your package size",
					  showConfirmButton: true,
					})
				}
			},

			get_totals(){
				this.total_proteins = 0
				this.total_carbs = 0
				this.total_fats = 0
				this.total_carlories = 0
				this.total_price = 0

				this.protein_value_details.forEach((data)=>{
					this.total_proteins+=parseFloat(data.proteins)
					this.total_carbs+=parseFloat(data.carbs)
					this.total_fats+=parseFloat(data.fats)
					this.total_carlories+=parseFloat(data.carlories)
					this.total_price+=parseFloat(data.price)
				})

				this.carb_value_details.forEach((data)=>{
					this.total_proteins+=parseFloat(data.proteins)
					this.total_carbs+=parseFloat(data.carbs)
					this.total_fats+=parseFloat(data.fats)
					this.total_carlories+=parseFloat(data.carlories)
					this.total_price+=parseFloat(data.price)
				})

				this.veg_value_details.forEach((data)=>{
					this.total_proteins+=parseFloat(data.proteins)
					this.total_carbs+=parseFloat(data.carbs)
					this.total_fats+=parseFloat(data.fats)
					this.total_carlories+=parseFloat(data.carlories)
					this.total_price+=parseFloat(data.price)
				})

			},

			async get_protein_value_details(){
				if(!this.protein_value_id){
					return
				}
			
				const res = await axios.get(this.$store.state.url+'api/get-protein-value-details/'+this.protein_value_id).then(function(response){
					return response.data
				}).catch(function(error){
					console.log(error)
				})
				this.protein_value_details = res
				res.forEach((data)=>{
					this.protein_category_name = data.category_name 
					this.protein_name = data.product
					this.protein_value = data.value
				})
				this.get_totals()
				
			},

				async get_carb_value_details(){
				if(!this.carb_value_id){
					return
				}
			
				const res = await axios.get(this.$store.state.url+'api/get-carb-value-details/'+this.carb_value_id).then(function(response){
					return response.data
				}).catch(function(error){
					console.log(error)
				})
				this.carb_value_details = res
				res.forEach((data)=>{
					this.carb_category_name = data.category_name 
					this.carb_name = data.product
					this.carb_value = data.value
				})
				this.get_totals()
				
			},

			async get_veg_value_details(){
				if(!this.veg_value_id){
					return
				}
			
				const res = await axios.get(this.$store.state.url+'api/get-veg-value-details/'+this.veg_value_id).then(function(response){
					return response.data
				}).catch(function(error){
					console.log(error)
				})
				this.veg_value_details = res
				res.forEach((data)=>{
					this.veg_category_name = data.category_name 
					this.veg_name = data.product
					this.veg_value = data.value
				})
				this.get_totals()
				
			},

			async get_protein_product_values(){

				this.protein_product_values = []
				this.protein_value_details = []

				if(!this.protein_product_id){
					return
				}
			
				const res = await axios.get(this.$store.state.url+'api/get-protein-product-values/'+this.protein_product_id).then(function(response){
					return response.data
				}).catch(function(error){
					console.log(error)
				})
				this.protein_product_values = res
			},

			async get_veg_product_values(){

				this.veg_product_values = []
				this.veg_value_details = []

				if(!this.veg_product_id){
					return
				}
			
				const res = await axios.get(this.$store.state.url+'api/get-veg-product-values/'+this.veg_product_id).then(function(response){
					return response.data
				}).catch(function(error){
					console.log(error)
				})
				this.veg_product_values = res
			},

			async get_carb_product_values(){
				this.carb_product_values = []
				this.carb_value_details = []

				if(!this.carb_product_id){
					return
				}
			
				const res = await axios.get(this.$store.state.url+'api/get-carb-product-values/'+this.carb_product_id).then(function(response){
					return response.data
				}).catch(function(error){
					console.log(error)
				})
				this.carb_product_values = res
			},

			async get_protein_products(){

				const res = await axios.get(this.$store.state.url+'api/get-protein-products').then(function(response){
					return response.data
				}).catch(function(error){
					console.log(error)
				})
				this.protein_products = res 
			},

			async get_carb_products(){

				const res = await axios.get(this.$store.state.url+'api/get-carb-products').then(function(response){
					return response.data
				}).catch(function(error){
					console.log(error)
				})
				this.carb_products = res 
			},

			async get_veg_products(){

				const res = await axios.get(this.$store.state.url+'api/get-veg-products').then(function(response){
					return response.data
				}).catch(function(error){
					console.log(error)
				})
				this.veg_products = res 
			},


			add_to_cart(){

				if(this.$store.state.is_package_selected!='yes'){

				Swal.fire({
					  title: "Please note",
					  icon: "info",
					  text: "You must select a package first before adding a meal to cart",
					  showConfirmButton: true,
					 
					})
				return
				}

				let item  = { 'protein_value_id' : this.protein_value_id, 'carb_value_id' : this.carb_value_id, 'veg_value_id' : this.veg_value_id, 'qty' : this.qty, 'total_proteins' : this.total_proteins, 'total_carbs' : this.total_carbs, 'total_fats' : this.total_fats, 'total_carlories' : this.total_carlories,'total_price' : this.total_price, 'protein_category_name' : this.protein_category_name, 'protein_name' : this.protein_name, 'protein_value' : this.protein_value, 'carb_category_name' :this .carb_category_name, 'carb_name' : this.carb_name, 'carb_value' : this.carb_value, 'veg_category_name' : this.veg_category_name, 'veg_name' : this.veg_name, 'veg_value' : this.veg_value }

												
				if( ( parseInt(this.$store.state.final_total_qty) + parseInt(this.qty) ) > parseInt(this.$store.state.selected_package) ){
					Swal.fire({
					  title: "Error",
					  text: "Items in cart exceeds your package size",
					  icon: "error"
					})

					}else{
				this.$store.state.custom_meal_cart = this.$store.state.custom_meal_cart.concat(item)
				localStorage.setItem('custom_meal_cart',JSON.stringify(this.$store.state.custom_meal_cart))

				this.$store.state.final_total_qty = parseInt(this.$store.state.final_total_qty) + parseInt(this.qty)

				localStorage.setItem('final_total_qty',this.$store.state.final_total_qty)
				
				Swal.fire({
				  title: "Success",
				  text : 'Item added to cart',
				  icon : 'success',
				  showDenyButton: true,
				  showCancelButton: true,
				  confirmButtonText: "View cart",
				  denyButtonText: `Continue shopping`
				}).then((result) => {
				  /* Read more about isConfirmed, isDenied below */
				  if (result.isConfirmed) {
				    this.$router.push('/cart')
				  } else if (result.isDenied) {
				    //pass
				  }
				})

				
					}
					this.qty = 1

			},



			select_package(p,id){
				localStorage.setItem('is_package_selected','yes')
				localStorage.setItem('selected_package',p)
				localStorage.setItem('package_id',id)
				this.$store.state.is_package_selected = 'yes'
				this.$store.state.selected_package = p
				this.$store.state.package_id = id 

				Swal.fire({
					  title: "Success",
					  icon: "success",
					  text: "Package selected successfully.You can now add items to cart",
					  showConfirmButton: true,
					 
					})
			},

				async get_packages(){

				const res = await axios.get(this.$store.state.url+'api/get-packages').then(function(response){
					return response.data
				}).catch(function(error){
					console.log(error)
				})
				this.packages = res 

			}

				
		},
		created(){
			this.get_protein_products()
			this.get_carb_products()
			this.get_veg_products()
		
			this.get_packages()

			if(!this.$store.state.final_total_qty){
				this.$store.state.final_total_qty = 0 
			}


		}

	}

</script>