<template>

	<Header />
	

	<section style="padding-top:100px; padding-bottom: 100px;">
		
		<div style="text-align:center;">
			<h1 class="text-danger"> <i class="bi bi-x-circle"></i> </h1>
		<h1 style="font-size:10vw;" class="text-danger">404</h1>
		<h3>Page does not exist!</h3>	
		</div>

	</section>


	<Footer />


</template>


<script>
	import Header from './layouts/Header'
	import Footer from './layouts/Footer'
	export default{
		name : '404',
		components : {Header,Footer}
	}

</script>