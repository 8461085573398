<template>


	<TopBar />

	<div class="main-container">


	<SideBar />
 
		 


		<div class="main"> 

			<div class="shadow rounded p-3 mt-3 card-design">
			

			<div class="row">
				<div class="col-md-6 col-6">
					<h3>New Package</h3>
				</div>
				<div class="col-md-6 col-6" style="text-align: right;">
					<router-link class="btn btn-1" to="/view-packages"> <i class="bi bi-grid"></i> View packages</router-link>
				</div>
			</div>


			<div>
				
				<form @submit.prevent="new_package()" enctype="multipart/form-data">
					<div class="row">
						<div class="col-md-4 mt-3">
							<label>Package name</label>
							<input type="text" v-model="name" class="form-control" placeholder="Enter name of the package">
						</div>

						<div class="col-md-4 mt-3">
							<label>Quantity</label>
							<input type="text" v-model="qty" placeholder="Enter number of items in the package"  class="form-control">
						</div>

						<div class="col-md-4 mt-3">
							<label>Discount(%)</label>
							<input type="text" v-model="discount" class="form-control" placeholder="Enter % discount">
						</div>

						<div class="col-md-12 mt-3">
						<button class="btn btn-1" :disabled="disabled">{{text}}</button>	
						</div>

					</div>
				</form>

			</div>


			</div>

	

		

			
		</div> 
	</div> 
	


</template>


<script>
	import SideBar from './layouts/SideBar'
	import TopBar from './layouts/TopBar'
	import Swal from 'sweetalert2'
	import axios from 'axios'

	export default{
		name : 'header',
		components : {SideBar,TopBar},
		data(){
			return{
				name : '',
				qty : 1,
				discount : 0,
				text : 'Submit',
				disabled : false
			}
		},
		methods : {

			async new_package(){
				if(!this.name){
					Swal.fire({
					  position: "top-center",
					  icon: "error",
					  text: "Package name is required!",
					  showConfirmButton: false,
					  timer: 1500
					})
					return
				}


				if(!this.qty){
					Swal.fire({
					  position: "top-center",
					  icon: "error",
					  text: "Quantity is required!",
					  showConfirmButton: false,
					  timer: 1500
					})
					return
				}

				if(!this.discount){
					Swal.fire({
					  position: "top-center",
					  icon: "error",
					  text: "Discount is required!",
					  showConfirmButton: false,
					  timer: 1500
					})
					return
				}

				this.text = 'Please wait...'
				this.disabled = true
				

				const res = await axios.post(this.$store.state.url+'api/new-package',{
					name : this.name,
					qty : this.qty,
					discount : this.discount
				}).then(function(response){
					return response.data
				}).catch(function(error){
					console.log(error)
				})

				if(res=='success'){
					Swal.fire({
					  position: "top-center",
					  icon: "success",
					  text: "Package added successfully",
					  showConfirmButton: false,
					  timer: 1500
					})	
				}else{
					Swal.fire({
					  position: "top-center",
					  icon: "error",
					  text: res,
					  showConfirmButton: false,
					  timer: 1500
					})
				}
				this.text = 'Submit'
				this.disabled = false

				this.name = ''
				this.qty = ''
				this.discount = ''

			}

		}

	}

</script>