<template>


	<TopBar />

	<div class="main-container">


	<SideBar />
 
		 


		<div class="main"> 

			<div class="shadow rounded p-3 mt-3 card-design">
			

			<div class="row">
				<div class="col-md-6 col-6">
					<h3>Nutrient Products</h3>
				</div>
				<div class="col-md-6 col-6" style="text-align: right;">
					<router-link class="btn btn-1" to="/new-nutrient-product"> <i class="bi bi-plus"></i> Add new</router-link>
				</div>
			</div>

			<div class="table-responsive">
				
				<table class="table table-hover table-striped">

					<thead>
						<tr>
							 <th>Name</th> <th>Nutrient</th> <th> Action </th> 
						</tr>
					</thead>

					<tbody>
						<tr v-for="p in products">
							<td>  {{p.name}} </td>
							<td> {{p.category}} </td>
							<td> <button @click="delete_product(p.id)" class="btn btn-danger"> <i class="bi bi-x"></i> Delete</button> </td>
							
						</tr>
					</tbody>
					
				</table>
				

			</div>


			</div>

	

		

			
		</div> 
	</div> 
	


</template>


<script>
	import SideBar from './layouts/SideBar'
	import TopBar from './layouts/TopBar'
	import Swal from 'sweetalert2'
	import axios from 'axios'

	export default{
		name : 'header',
		components : {SideBar,TopBar},
		data(){
			return{
				products : []
			}
		},
		methods : {


			async delete_product(id){

				const res = await axios.post(this.$store.state.url+'api/delete-nutrient-product',{
					id : id 
				}).then(function(response){
					return response.data
				}).catch(function(error){
					console.log(error)
				})
				this.get_products() 
			},

				async delete_category(id){

				const res = await axios.post(this.$store.state.url+'api/delete-product-category',{
					id : id 
				}).then(function(response){
					return response.data
				}).catch(function(error){
					console.log(error)
				})
				this.get_products() 
			},

			async get_products(){

				const res = await axios.get(this.$store.state.url+'api/nutrient-products').then(function(response){
					return response.data
				}).catch(function(error){
					console.log(error)
				})
				this.products = res 
			}

		},

		created(){
			this.get_products()
		}

	}

</script>