<template>
	<Header />



	<section style="padding-top:10px; padding-bottom: 100px;">


		<div class="container">

			<div class="row">
				<div class="col-md-12">

					<div style="text-align:left;">
						<h6 class="text-danger">Welcome,{{pin}} </h6>
					</div>


					<div class="table-responsive">
						
						<table class="table table-hover table-striped">
							
							<thead>
								<tr>
									<th>Order#</th>
									<th>Delivery location</th>
									<th>Phone</th>
									<th>Action</th>
								</tr>
							</thead>

							<tbody>
								
								<tr v-for="o in orders">
									
									<td> {{o.order_number}} </td>
									<td> {{o.location_name}} </td>
									<td> {{o.phone}} </td>

									<td>

										<p v-if="o.status=='arrived'"> <i class="bi bi-check color-1"></i> Delivered</p>
										
										<button v-if="o.status!='arrived'" :disabled="disabled" class="btn btn-2" @click="change_status(o.order_number,o.first_name,o.email,o.phone)"> {{text}} </button>

									</td>

								</tr>

							</tbody>

						</table>

					</div>
					
				</div>
			
				
			</div>
			
		</div>
		


	</section>


	<Footer />


</template>

<script>
	import Header from './layouts/Header'
	import Footer from './layouts/Footer'
	import axios from 'axios'
	import Swal from 'sweetalert2'
	export default{
		name : 'home',
		components : { Header,Footer }, 
		data(){
			return{
				pin : '',
				orders : [],
				text : 'Arrive',
				disabled : false
			}
		},

		methods : {

			async change_status(order_number,name,email,phone){
				this.text = 'Please wait...'
				this.disabled = true
				const res = await axios.post(this.$store.state.url+'api/change-order-status',{
					order_number : order_number,
					status : 'arrived',
					name : name,
					email : email,
					phone : phone
				}).then(function(response){
					return response.data
				}).catch(function(error){
					console.log(error)
				})

				this.rider_orders() 
				this.text = 'Arrive'
				this.disabled = false

			},

			async rider_orders(){
				const res = await axios.get(this.$store.state.url+'api/rider-orders/'+this.pin).then(function(response){
					return response.data
				}).catch(function(error){
					console.log(error)
				})

				this.orders = res 

			}

		},

		created(){
			this.pin = localStorage.getItem('pin')
			this.rider_orders()
		}
	}

</script>