<template>


	<TopBar />

	<div class="main-container">


	<SideBar />
 
		 


		<div class="main"> 

			<div class="shadow rounded p-3 mt-3 card-design">
			

			<div class="row">
				<div class="col-md-6 col-6">
					<h3>Product Categories</h3>
				</div>
				<div class="col-md-6 col-6" style="text-align: right;">
					<router-link class="btn btn-1" to="/new-product"> <i class="bi bi-plus"></i> Add new</router-link>
				</div>
			</div>

			<div class="table-responsive">
				
				<table class="table table-hover table-striped">

					<thead>
						<tr>
							<th>Image</th> <th>Name</th> <th>Category</th> <th>Type</th> <th>Price</th> <th>Description</th>  <th>Action</th>
						</tr>
					</thead>

					<tbody>
						<tr v-for="p in products">
							<td> <img :src="this.$store.state.url+'assets/product-images/'+p.image" style="height:70px;" class="shadow rounded"> </td>
							<td> {{p.name}} </td>
							<td> {{p.category}} </td>
							<td> {{p.type}} </td>
							<td> {{p.price}} </td>
							<td> <div v-html="p.description"></div> </td>
							
							<td> <div class="dropdown">
  <button class="btn btn-outline-success dropdown-toggle" type="button" id="dropdownMenuButton1" data-bs-toggle="dropdown" aria-expanded="false">
    Manage
  </button>
  <ul class="dropdown-menu" aria-labelledby="dropdownMenuButton1">
    <li><router-link :to=" { name : 'edit-product', params : { product_number : p.product_number } } " class="dropdown-item">Edit</router-link></li>
    <li><button @click="delete_product(p.id)"  class="dropdown-item">Delete</button></li>
   
  </ul>
</div> </td>
						</tr>
					</tbody>
					
				</table>
				

			</div>


			</div>

	

		

			
		</div> 
	</div> 
	


</template>


<script>
	import SideBar from './layouts/SideBar'
	import TopBar from './layouts/TopBar'
	import Swal from 'sweetalert2'
	import axios from 'axios'

	export default{
		name : 'header',
		components : {SideBar,TopBar},
		data(){
			return{
				products : []
			}
		},
		methods : {


				async delete_product(id){

				const res = await axios.post(this.$store.state.url+'api/delete-product',{
					id : id 
				}).then(function(response){
					return response.data
				}).catch(function(error){
					console.log(error)
				})
				this.get_products() 
			},

			async get_products(){

				const res = await axios.get(this.$store.state.url+'api/list-products').then(function(response){
					return response.data
				}).catch(function(error){
					console.log(error)
				})
				this.products = res 
			}

		},

		created(){
			this.get_products()
		}

	}

</script>