<template>
	<div class="navcontainer"> 
			<nav class="nav"> 
				<div class="nav-upper-options"> 
					<div class="nav-option"> 
					<router-link class="btn option1" to="/dashboard"><i class="bi bi-speedometer"></i> Dashboard</router-link> 
					</div> 


					<div class="nav-option">
	<div class="dropdown">
  <button class="btn option1 dropdown-toggle" type="button" id="dropdownMenuButton1" data-bs-toggle="dropdown" aria-expanded="false">
     <i class="bi bi-grid"></i> Product categories
  </button>
  <ul class="dropdown-menu" aria-labelledby="dropdownMenuButton1">
    <li><router-link to="/new-product-category" class="dropdown-item">Add new</router-link></li>
    <li><router-link to="/product-categories" class="dropdown-item">View  categories</router-link></li>

  </ul>
</div>
						
					</div>




		

		<div class="nav-option">
	<div class="dropdown">
  <button class="btn option1 dropdown-toggle" type="button" id="dropdownMenuButton1" data-bs-toggle="dropdown" aria-expanded="false">
     <i class="bi bi-layers"></i> Products
  </button>
  <ul class="dropdown-menu" aria-labelledby="dropdownMenuButton1">
    <li><router-link to="/new-product" class="dropdown-item">Add new</router-link></li>
    <li><router-link to="/view-products" class="dropdown-item">View  products</router-link></li>

  </ul>
</div>
</div>




		<div class="nav-option">
	<div class="dropdown">
  <button class="btn option1 dropdown-toggle" type="button" id="dropdownMenuButton1" data-bs-toggle="dropdown" aria-expanded="false">
     <i class="bi bi-sliders"></i> Custom Meals
  </button>
  <ul class="dropdown-menu" aria-labelledby="dropdownMenuButton1">
  	<li><h6 class="dropdown-header">Categories</h6></li>
    <li><router-link to="/new-nutrient-category" class="dropdown-item">New category</router-link></li>
    <li><router-link to="/nutrient-categories" class="dropdown-item">View  categories</router-link></li>

    <li><hr class="dropdown-divider"></li>
    <li><h6 class="dropdown-header">Products</h6></li>

    <li><router-link to="/new-nutrient-product" class="dropdown-item">New product</router-link></li>

     <li><router-link to="/nutrient-products" class="dropdown-item">View products</router-link></li>


       <li><hr class="dropdown-divider"></li>
       <li><h6 class="dropdown-header">Nutrient values</h6></li>

    <li><router-link to="/new-nutrient-value" class="dropdown-item">New nutrient value</router-link></li>

     <li><router-link to="/nutrient-values" class="dropdown-item">View values</router-link></li>

  </ul>
</div>
</div>










								<div class="nav-option">
	<div class="dropdown">
  <button class="btn option1 dropdown-toggle" type="button" id="dropdownMenuButton1" data-bs-toggle="dropdown" aria-expanded="false">
     <i class="bi bi-collection"></i> Packages
  </button>
  <ul class="dropdown-menu" aria-labelledby="dropdownMenuButton1">
    <li><router-link to="/new-package" class="dropdown-item">Add new</router-link></li>
    <li><router-link to="/view-packages" class="dropdown-item">View  packages</router-link></li>

  </ul>
</div>
						
					</div>



							<div class="nav-option">
	<div class="dropdown">
  <button class="btn option1 dropdown-toggle" type="button" id="dropdownMenuButton1" data-bs-toggle="dropdown" aria-expanded="false">
     <i class="bi bi-truck"></i> Delivery locations
  </button>
  <ul class="dropdown-menu" aria-labelledby="dropdownMenuButton1">
    <li><router-link to="/new-location" class="dropdown-item">Add new</router-link></li>
    <li><router-link to="/view-locations" class="dropdown-item">View  locations</router-link></li>

  </ul>
</div>
						
					</div>




							<div class="nav-option">
	<div class="dropdown">
  <button class="btn option1 dropdown-toggle" type="button" id="dropdownMenuButton1" data-bs-toggle="dropdown" aria-expanded="false">
     <i class="bi bi-people"></i> Riders
  </button>
  <ul class="dropdown-menu" aria-labelledby="dropdownMenuButton1">
    <li><router-link to="/new-rider" class="dropdown-item">Add new</router-link></li>
    <li><router-link to="/view-riders" class="dropdown-item">View  riders</router-link></li>

  </ul>
</div>
						
					</div>



						<div class="nav-option"> 
					<router-link class="btn option1" to="/orders"><i class="bi bi-list"></i> Orders</router-link> 
					</div>


						<div class="nav-option"> 
					<button @click="logout()" class="btn option1"><i class="bi bi-power"></i> Log Out</button> 
					</div> 


					
				</div> 
			</nav> 
		</div>
</template>


<script>
	export default{

		name : 'sidebar',

		methods : {
			logout(){
				this.$router.push('/sudo')
			}
		}

	}

</script>